@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --footer-bg: #225071;
  --footer-btn-bg-color: #8fd0ff;
  --primary-color: #21a2ff;
  --secondary-color: #5685a7;
  --white-color: #ffffff;
  --grey-light-color: #f5f5f5;
  --text-primary: #3f4040;
  --text-grey-color: #656565;
  --border-color: #dfdfdf;
  --border-light-color: #e2e2e2c8;
  --icon-color: #656565;
  --icon-light-color: #a0a0a0;
}

* {
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

/* LAYOUT */

.flex {
  display: flex;
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.mg-l-auto {
  margin-left: auto;
}

.gap-0 {
  gap: 10px;
}

.gap-1 {
  gap: 15px;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 25px;
}

.gap-4 {
  gap: 30px;
}

.pointer {
  cursor: pointer;
}

.grid {
  display: grid;
}

.grid-page {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 25px;
  /* padding: 0px 15px; */
}

.grid-page h3 {
  font-weight: 500;
  font-size: 35px;
  margin-bottom: 10px;
  color: var(--text-grey-color);
}

.grid-page h4 {
  font-size: 25px;
  margin-top: 30px;
  color: var(--text-primary);
}

.grid-page p {
  font-size: 14px;
  margin: 15px 0px;
  line-height: 20px;
  color: var(--text-primary);
}

.grid-page ul {
  padding-left: 20px;
  margin-left: 15px;
}

.grid-page ul li {
  list-style-type: square;
  color: var(--text-primary);
  font-size: 14px;
  padding-bottom: 5px;
}

.col-3 {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 16px;
}

/* COMPONENTS */

.link {
  color: var(--primary-color);
}

.small-text {
  font-size: 11px;
}

/* HEADER */

.wrapper.full {
  width: 100%;
}

.wrapper.auto {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* header,
#main-header,
#main-content-inner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
} */

#header-container {
  height: 60px;
  background-color: var(--white-color);
  border-bottom: 1px solid var(--border-color);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 4;
}

header {
  height: 100%;
  /* margin: 0 auto; */
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header #header-center {
  margin-right: 1px;
}

#logo-main {
  width: 35px;
  margin-right: 15px;
}

.logo-text {
  color: var(--primary-color);
  font-family: "Jost", sans-serif;
  font-size: 25px;
  font-weight: 600;
}

header .btn.icon-btn {
  /* display: none; */
}

#nav-list {
  position: sticky;
  top: 60px;
  z-index: 3;
}

#nav-list li {
  padding: 10px 12px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 14px;
  border-radius: 2px;
  color: var(--text-primary);
  font-weight: 500;
  margin: 0px 2.5px;
}

#nav-list li.active,
#nav-list li:hover {
  background-color: var(--bg-color);
  color: var(--primary-color);
}

#nav-list.body {
  display: none;
  background-color: var(--white-color);
  padding: 8px 0px;
  padding-left: 10px;
  border-bottom: 1px solid var(--border-color);
}

.btn {
  cursor: pointer;
  /* background-color: var(--primary-color); */
  color: var(--white-color);
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 2px;
  padding: 8px 12px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.btn.icon-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 35px;
  height: 35px;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  background-color: var(--secondary-color) !important;
  color: var(--white-color) !important;
}

/* MAIN HEADER */

#main-header {
  margin-bottom: 5px;
  padding: 0px 16px;
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-gap: 60px;
}

#main-header .anim-banner {
  width: 100%;
  border-radius: 25px;
}

#form {
  padding: 70px 0px;
  min-height: 200px;
}

#form h2 {
  font-size: 55px;
  /* font-family: "Jost", sans-serif; */
}

#form > p {
  margin-top: 12px;
  color: var(--text-grey-color);
}

.form-input-group {
  margin-top: 20px;
  background-color: var(--white-color);
  border-radius: 2px;
  border: 1px solid var(--border-color);
}

.form-input-group .left {
  flex: 1;
  align-items: stretch;
}

.form-input-group .left .email-input-container {
  display: flex;
  flex: 1;
  align-items: center;
}

.form-input-group .left .email-input-container .icon {
  color: var(--icon-light-color);
  padding: 0px 10px;
}

.form-input-group .left .email-input-container input {
  height: 45px;
  width: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-self: flex-end;
  align-items: center;
  min-width: 80px;
  padding: 0px 12px;
  cursor: pointer;
  height: 100%;
}

.dropdown-list {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-list-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.show {
  display: block;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  /* padding-top: 50px; Location of the box */
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  /* overflow: auto; Enable scroll if needed */
  overflow: hidden;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #ffffff;
  margin: auto;
  padding: 50px 20px 20px 20px;
  border: 1px solid #888;
  width: 65%;
  text-align: center;
  position: relative;
}

.modal-content h3.title {
  font-size: 25px;
  max-width: 70%;
  margin: 0 auto;
}

.modal-content h3.sub-title {
  font-size: 16px;
}

.modal .sub-title {
  font-size: 12px;
  max-width: 70%;
  margin: 0 auto;
  color: var(--text-grey-color);
  margin-top: 15px;
}

.modal-content-inner {
  margin: 50px auto;
}

.modal .footer-text {
  font-size: 10px;
  max-width: 70%;
  margin: 0 auto;
  color: var(--text-grey-color);
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: var(--primary-color);
  text-decoration: none;
}

/* MAIN CONTENT */
#main-content {
  background-color: var(--white-color);
  padding: 40px 0px;
  min-height: 50vh;
}

#main-content-inner {
  /* padding: 20px 15px; */
}

/* MAST HEAD */

#mast-head {
  margin-top: 60px;
  margin-bottom: 2px;
  height: 120px;
  background-color: var(--white-color);
}

#mast-head #mast-head-inner {
  height: 100%;
  padding: 0px 20px 0px 15px;
  overflow: hidden;
}

#mast-head #mast-head-inner h1 {
  color: var(--text-primary);
}

#mast-head #mast-head-inner .mast-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150px;
  color: var(--primary-color);
  border-radius: 95px;
  background-color: var(--bg-color);
  padding: 20px;
}

/* Extra Extra small devices (phones, 350px and down) */
@media only screen and (max-width: 350px) {
  header {
    padding: 0px 10px;
  }

  header .logo-text {
    font-size: 18px !important;
  }

  header #logo-main {
    width: 30px;
    margin-right: 10px;
  }

  header #header-center {
    display: none;
  }

  #nav-list.body {
    display: flex;
    padding-left: 10px;
    margin-top: 60px;
  }

  #nav-list.body li {
    font-size: 11px;
  }

  header .btn.icon-btn {
    font-size: 12px;
  }

  #form {
    padding: 30px 0px;
    min-height: 150px;
  }

  #form .modal-btn {
    min-width: 50px !important;
    padding-left: 8px !important;
  }

  #form h2 {
    font-size: 28px;
    /* font-family: "Jost", sans-serif; */
  }

  #form > p {
    margin-top: 12px;
    font-size: 13px;
  }

  #main-header {
    display: grid;
    grid-template-columns: 1fr;
  }

  #main-header .header-anim {
    display: none;
  }

  /* MAST HEAD */
  #mast-head {
    height: 50px !important;
    margin-top: 0px;
  }

  #mast-head #mast-head-inner h1 {
    font-size: 18px !important;
  }

  #mast-head #mast-head-inner .mast-icon {
    font-size: 40px !important;
  }

  /* MODAL */

  .modal-content {
    width: 90%;
  }

  .grid-page {
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
  }

  .grid-page h3 {
    font-size: 25px;
    margin-bottom: 0px;
  }

  .grid-page h4 {
    font-size: 16px;
  }

  .grid-page p {
    font-size: 13px;
  }

  .col-3 {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
}

/* Extra small devices (phones, 350px and down) */
@media only screen and (max-width: 739px) {
  header {
    padding-left: 15px;
    padding-right: 15px;
  }

  #main-content-inner {
    padding: 10px 0px;
  }

  header .logo-text {
    font-size: 25px;
  }

  header #header-center {
    display: none;
  }

  #nav-list.body {
    display: flex;
    margin-top: 60px;
  }

  #form {
    padding: 40px 0px;
    min-height: 100px;
  }

  #form h2 {
    font-size: 28px;
    margin-bottom: 0px;
  }

  #form > p {
    margin-top: 12px;
    font-size: 13px;
  }

  #form .modal-btn {
    min-width: 90px !important;
    padding-left: 8px !important;
  }

  .modal-content {
    width: 90%;
  }

  .modal-content > h3 {
    max-width: 100% !important;
    font-size: 20px !important;
  }

  .modal-content > p {
    max-width: 100% !important;
    font-size: 13px !important;
  }

  #main-header {
    display: grid;
    grid-template-columns: 1fr;
  }

  #main-header .header-anim {
    display: none;
  }
  #main-content {
    padding: 20px 0px;
  }

  /* MAST HEAD */
  #mast-head {
    height: 80px;
    margin-top: 0px;
  }

  #mast-head #mast-head-inner {
    padding-right: 15px;
  }

  #mast-head #mast-head-inner h1 {
    font-size: 28px;
  }

  #mast-head #mast-head-inner .mast-icon {
    font-size: 80px;
  }

  /* layout */
  .grid-page {
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
  }

  .grid-page h3 {
    font-size: 30px;
    margin-bottom: -10px;
  }

  .grid-page h4 {
    font-size: 18px;
  }

  .grid-page p {
    font-size: 13px;
  }

  .col-3 {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 12px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 740px) {
  header {
    padding: 0px 15px;
  }

  #main-content {
    padding: 35px 0px;
  }

  header .logo-text {
    font-size: 25px;
  }

  header #header-center {
    display: flex;
  }

  #main-header {
    margin-top: 60px;
  }

  #nav-list.body {
    display: none;
  }
  #main-header {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 60px;
  }

  #form h2 {
    font-size: 35px;
    margin-bottom: 0px;
  }

  #main-header .header-anim {
    display: flex;
  }

  .grid-page {
    padding: 0px 16px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
} */

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #form h2 {
    font-size: 55px;
    margin-bottom: 0px;
  }

  #form > p {
    margin-top: 18px;
    font-size: 14px;
    color: var(--text-grey-color);
  }

  #form .modal-btn {
    min-width: 150px !important;
    padding-left: 8px !important;
  }
  #main-header {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-gap: 60px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
