#footer {
  background-color: var(--footer-bg);
  margin-top: auto;
}

.footer {
  min-height: 120px;
  padding: 20px 15px;
}

.footer .btn {
  background-color: var(--footer-btn-bg-color);
  color: var(--footer-bg);
}

.footer .top,
.footer .top .left,
.footer .top .right {
  flex-wrap: wrap;
}

.footer .top {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-light-color);
}

.footer .top .logo-text {
  font-size: 25px !important;
  color: var(--bg-color);
}

.footer .top .links li {
  color: var(--white-color);
  text-decoration: underline;
  font-size: 14px;
}

.footer .bottom {
  padding-top: 20px;
}

.footer .bottom .copy {
  color: var(--icon-light-color);
  font-size: 13px;
}

@media only screen and (max-width: 440px) {
  .footer .top .right,
  .footer .top .right .btn {
    width: 100%;
  }

  .footer .top .right .btn {
    justify-content: flex-end;
    /* gap: 30px !important; */
  }
}
