.logo-wrapper {
  position: relative;
}

.logo-wrapper .icon {
  position: absolute;
  bottom: -6px;
  right: 5px;
  background-color: var(--white-color);
  font-size: 14px;
  padding: 3px;
  border-radius: 25px;
  color: var(--primary-color);
}
