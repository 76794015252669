#error-page {
  padding: 20px;
  text-align: center;
  color: var(--text-primary);
}

._404_image {
  max-width: 400px;
  margin: 0 auto;
}

#error-page p {
  font-size: 14px;
  line-height: 20px;
  margin: 5px 0px 15px 0px;
}

/* MEDIA QUERY */
@media only screen and (max-width: 739px) {
  #error-page {
    text-align: left;
  }
  ._404_image {
    max-width: 100%;
  }
}
