/* Cards and CONTAINERS */

.item-square-card {
  width: 150px;
  height: 150px;
  border-radius: 2px;
  border: 2px solid var(--border-color);
  padding: 10px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.item-square-card:hover,
.item-square-card.active {
  /* background-color: var(--bg-color); */
  border-color: var(--primary-color);
}

.item-square-card .icon {
  font-size: 45px;
  margin: auto 0px;
  color: var(--primary-color);
}

.item-square-card .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.item-square-card .item-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.item-square-card .item-desc {
  font-size: 11px;
  color: var(--text-grey-color);
}

/* SERVICE LIST CARD */

.service-list-card {
  max-width: 250px;
  padding: 15px 0px;
}

.service-list-card .icon {
  width: 80px;
  height: 80px;
  font-size: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: var(--bg-color);
  color: var(--primary-color);
}

.service-list-card .content .card-title {
  font-weight: 600;
}

.service-list-card .content .desc {
  font-size: 12px;
  color: var(--text-grey-color);
  width: 90%;
}

/* TOGGLE LIST CARD - FAQ */

.toggle-list-card {
  display: flex;
  align-items: flex-start;
  /* margin: 5px 0px; */
  border-bottom: 1px solid var(--border-color);
}

.toggle-list-card:first-child {
  border-top: 1px solid var(--border-color);
}

.toggle-list-card :first-child {
  width: 45px;
}
/* .toggle-list-card > * {
  flex: 45px 100%;
} */

.toggle-list-card .icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  width: 25px;
  height: 45px;
  margin-right: 15px;
  color: var(--primary-color);
  font-size: 25px;
}

.toggle-list-card .content {
  transition: all 0.5s ease;
}

.toggle-list-card .content .head {
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  padding: 15px 0px;
  letter-spacing: 0.5px;
  /* border-bottom: 1px solid var(--border-color); */
}

.toggle-list-card .content .body {
  width: 90%;
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-primary);
  display: none;
  /* opacity: 0; */
  /* perspective: 1000px; */
  background-color: var(--bg-color);
  transition: all 0.5s ease;
}

.toggle-list-card.typedtext .content .body {
  display: block;
}

.toggle-list-card .content .body.show {
  opacity: 1;
  transform: translateY(0);
  display: block;
}

/* MEDIA QUERY */
@media only screen and (max-width: 739px) {
  .service-list-card {
    max-width: 100%;
  }
}
